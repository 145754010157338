import React from "react"
import _ from "lodash"
import HeaderWithIcon from "../components/common/HeaderWithIcon"
import SubPageHeader from "../components/header/SubPageHeader"
import ArrowNavigation from "../components/navigation/ArrowNavigation"
import Layout from "../components/layout"
import RiskCalculator from "../components/riskCalculator/RiskCalculator"
import { getLinkToSubpage } from "../lib/utils"
import styles from "../styles/content.module.styl"
import textStyles from "../styles/textContent.module.styl"

const Risk = ({ location, data, pathContext }) => {
  const nodeData = data.decisionAidsJson
  const { pageId, groupId, subPage, group } = pathContext

  const prevLink = getLinkToSubpage(pageId, groupId, "options")
  const nextLink = getLinkToSubpage(pageId, groupId, "summary")

  return (
    <Layout pageId={pageId} withGlossaryButton>
      <SubPageHeader
        title={nodeData.title}
        group={group}
        hasGroups={!_.isEmpty(nodeData.groups)}
        location={location}
        logo={nodeData.logo}
        pageId={pageId}
        groupId={groupId}
        subPage={subPage}
      />
      <div className={styles.content}>
        <HeaderWithIcon title="Your Risk" type="risk" />
        <div className={textStyles.plainText}>
          <span className={styles.bold}>
            The American Society of Hematology
          </span>{" "}
          prepared recommendations for
          <span className={styles.bold}>
            3 different Venous thromboembolism (VTE) risk groups.
          </span>
          To assess your risk of VTE, please answer questions below
        </div>
        <RiskCalculator
          riskLabels={nodeData.riskLabels}
          questions={nodeData.riskQuestions}
        />
        <ArrowNavigation prevLink={prevLink} nextLink={nextLink} />
      </div>
    </Layout>
  )
}

export default Risk

export const query = graphql`
  query RisksQuery($pageId: String) {
    decisionAidsJson(pageId: { eq: $pageId }) {
      logo
      title
      riskQuestions {
        prefix
        content
        value
      }
    }
  }
`
