import classNames from "classnames"
import Immutable from "immutable"
import { kebabCase } from "lodash"
import PropTypes from "prop-types"
import React, { Component } from "react"
import { connect } from "react-redux"
import { updateSelectedRisks } from "../../state/actions/riskCalculatorActions"
import styles from "../../styles/content.module.styl"
import rcStyles from "../../styles/riskCalculator.module.styl"
import textStyles from "../../styles/texts.module.styl"
import ContentBox from "../common/ContentBox"

const mapStateToProps = state => ({
  selectedRisks: state.getIn(["riskCalculator", "selectedRisks"]),
})

const mapDispatchToProps = {
  updateSelectedRisks,
}

const Input = ({ checked, value, name, onChange }) => (
  <input
    className={rcStyles.rcRadioInput}
    type="radio"
    value={value}
    name={name}
    checked={checked}
    onChange={onChange}
  />
)

const InputGroup = ({ question, onChange, checked }) => (
  <div
    className={classNames(
      styles.flexRow,
      rcStyles.inputGroup,
      styles.alignCenter
    )}
  >
    {/* eslint-disable jsx-a11y/label-has-associated-control */}
    <label className={classNames(styles.flexRow, styles.alignCenter)}>
      <Input
        value="no"
        name={`${kebabCase(question.content)}`}
        checked={checked === "no"}
        onChange={onChange}
      />
      No
    </label>
    <label className={classNames(styles.flexRow, styles.alignCenter)}>
      <Input
        value="yes"
        checked={checked === "yes"}
        name={`${kebabCase(question.content)}`}
        onChange={onChange}
      />
      Yes
    </label>
  </div>
)

export class RiskCalculator extends Component {
  static propTypes = {
    selectedRisks: PropTypes.instanceOf(Immutable.Map).isRequired,
    questions: PropTypes.arrayOf(
      PropTypes.shape({
        prefix: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
      })
    ).isRequired,
  }

  constructor(props) {
    super(props)

    this.onRiskChange = this.onRiskChange.bind(this)
  }

  onRiskChange(e) {
    const { name, value } = e.target
    this.props.updateSelectedRisks({ name, value })
  }

  render() {
    const { questions, selectedRisks } = this.props

    return (
      <ContentBox className={rcStyles.riskCalculator}>
        <ul>
          {questions.map(question => (
            <li
              className={classNames(
                rcStyles.question,
                styles.flexRow,
                styles.alignCenter
              )}
              key={kebabCase(question.content)}
            >
              <div className={classNames(rcStyles.questionText)}>
                <span className={rcStyles.questionPrefix}>
                  {question.prefix}
                </span>
                <span
                  className={textStyles.blueText}
                  dangerouslySetInnerHTML={{ __html: question.content }}
                />
                <span>?</span>
              </div>
              <InputGroup
                checked={selectedRisks.get(kebabCase(question.content))}
                question={question}
                onChange={this.onRiskChange}
              />
            </li>
          ))}
        </ul>
      </ContentBox>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RiskCalculator)
